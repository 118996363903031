export const getDuration = durationInMillis => {
  const durationInMinutes = durationInMillis / 1000 / 60;
  let duration = Math.round(durationInMinutes);
  const displayInMinutes = durationInMinutes >= 1;
  if (!displayInMinutes) {
    duration = Math.round(durationInMinutes * 60);
  }
  return {
    unit: displayInMinutes ? 'minutes' : 'seconds',
    duration
  };
};
export const formatDuration = (durationInMillis, t) => {
  const {
    duration,
    unit
  } = getDuration(durationInMillis);
  return `${duration} ${t(unit === 'minutes' ? 'global.minutesShorthand' : 'global.secondsShorthand')}`;
};